import React, { useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"

import Dots from "./Dots"
import Close from "./Close"

import Button from "@bit/azheng.joshua-tree.button/Button"
import ImageMeta from "../ImageMeta"

const LifestyleNav = ({ location, customButton }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { di, fa } = useStaticQuery(
    graphql`
      query {
        di: allUniquePagesJson(
          filter: { title: { eq: "lifestyle-dental-implants/" } }
        ) {
          nodes {
            navbar {
              logo
              links {
                href
                name
                title
              }
              button {
                appearance
                buttonText
                destination
                href
              }
            }
          }
        }
        fa: allUniquePagesJson(
          filter: { title: { eq: "lifestyle-full-arch/" } }
        ) {
          nodes {
            navbar {
              logo
              links {
                href
                name
                title
              }
              button {
                appearance
                buttonText
                destination
                href
              }
            }
          }
        }
      }
    `
  )

  const type = location.includes("dental-implants") ? di : fa

  const { logo, button, links } = type.nodes[0].navbar
  let navButton = button
  if (customButton) {
    navButton = customButton
  }
  const toggleHamburger = () => setIsOpen(!isOpen)

  function gtag_report_conversion(phone) {
    if (typeof window !== "undefined") {
      window.gtag("event", "conversion", {
        send_to: "AW-11118537517/s5rPCJm15IAZEK3W3bUp",
        phone_conversion_number: phone,
      })
      return false
    }
  }

  return (
    <nav className="ls__nav" id="mainNav">
      <Link
        to="/"
        title="Return home"
        className="ls__nav-logo ls__nav--desktop"
      >
        <ImageMeta cloudName="nuvolum" publicId={logo} />
      </Link>

      <Link to="/" title="Return home" className="ls__nav-logo ls__nav--mobile">
        <ImageMeta cloudName="nuvolum" publicId={"MOFS/DEV/logo-mark"} />
      </Link>

      <div className="ls__nav-links ls__nav--desktop">
        {links.map((link) => (
          <Link key={link.href} to={link.href} title={link.title}>
            {link.name}
          </Link>
        ))}
      </div>

      <Button
        onClick={() => gtag_report_conversion(navButton.href)}
        buttonText={navButton.buttonText}
        href={navButton.href}
        className="contained ls__nav--desktop"
        tel={navButton.destination === "tel"}
      />

      {/* VV MOBILE NAV VV */}
      <div className="ls__nav-toggle ls__nav--mobile" onClick={toggleHamburger}>
        {isOpen ? <Close fill="#1d5794" /> : <Dots fill="#1d5794" />}
      </div>

      <div
        className={`ls__nav-links ls__nav-hamburger-container ${
          isOpen ? "open" : ""
        }`}
      >
        <div className="ls__nav-hamburger-links">
          {links.map((link) => (
            <Link key={link.href} to={link.href} title={link.title}>
              {link.name}
            </Link>
          ))}

          <Button
            onClick={() => gtag_report_conversion(navButton.href)}
            buttonText={navButton.buttonText}
            href={navButton.href}
            className="contained"
            tel={navButton.destination === "tel"}
          />
        </div>
      </div>
    </nav>
  )
}

export default LifestyleNav
