import { Link, graphql } from "gatsby"
import React from "react"
import classNames from "classnames"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"

import Navbar from "../components/Menu/LifestyleNav"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import NuvoImage from "../components/NuvoImage"
import ImageMeta from "../components/ImageMeta"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import TheaterVideo from "@components/Theater/TheaterVideo"

const LifestylePage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    isRobotsIndex,
    hero,
    patientSpotlight,
    about,
    callToAction,
  } = data.allUniquePagesJson.nodes[0]

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        robots={!isRobotsIndex ? "noindex" : ""}
      />
      <Navbar location={location.pathname} />
      <div className="lifestyle">
        <NuvoImage cloudName="nuvolum" publicId={hero.image} useAR />
        <div className="ls__section">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h1 className="has-text-centered">{hero.heading}</h1>
              <MarkdownViewer markdown={hero.blurb} />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="columns">
          {patientSpotlight.map((patient, i) => (
            <div key={i} className="column">
              <div className="ls__patient-spotlight">
                <ImageMeta cloudName="nuvolum" publicId={patient.image} />

                {patient.blurb && (
                  <div className="ls__spotlight-card">
                    <MarkdownViewer markdown={patient.blurb} />
                    <Link to={patient.href} title="Learn more">
                      <FontAwesomeIcon icon={faPlay} />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="ls__section color-back pb-0-mobile">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h2>{about.heading}</h2>

              <div className="ls__about-grid">
                <ImageMeta cloudName="nuvolum" publicId={about.image} />
                <div>
                  <MarkdownViewer markdown={about.blurb} />

                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${about.button.href}`}
                    controls
                    playing
                    buttonClass="contained ls__theater-btn"
                  />
                </div>
              </div>
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <CallToAction
          heading={callToAction.heading}
          blurb={callToAction.blurb}
          buttons={callToAction.buttons}
        />
      </div>
      <PrivacyFooter />
    </>
  )
}

export const CallToAction = ({
  heading,
  blurb,
  buttons,
  colorBack,
  centeredBlurb,
}) => {
  const blurbClasses = classNames({
    "has-text-centered": centeredBlurb,
  })

  return (
    <div className={`ls__section ${colorBack ? "color-back" : ""}`}>
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
          <h3 className="has-text-centered">{heading}</h3>
          <MarkdownViewer className={blurbClasses} markdown={blurb} />
          <ButtonGroupMap isCentered buttons={buttons} className="contained" />
        </div>
        <div className="column is-3"></div>
      </div>
    </div>
  )
}

export const PrivacyFooter = () => {
  return (
    <footer className="ls__privacy-footer">
      <Link to="/privacy-policy/" title="Read the privacy policy">
        Privacy Policy
      </Link>
      <Link to="/terms-of-use/" title="Read the terms of use">
        Terms of Use
      </Link>
      <Link
        to="/accessibility-statement/"
        title="Read the ADA accessibility statement"
      >
        ADA Accessibility Statement
      </Link>
      <Link to="/hipaa-policy/" title="Read the HIPAA compliance">
        HIPAA Compliance
      </Link>
    </footer>
  )
}

export const lifestylePageQuery = graphql`
  query lifestylePage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        isRobotsIndex
        hero {
          image
          heading
          blurb
        }
        patientSpotlight {
          blurb
          image
          href
        }
        about {
          heading
          blurb
          image
          button {
            href
            appearance
            buttonText
            destination
          }
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              href
              appearance
              buttonText
              destination
            }
          }
        }
      }
    }
  }
`

export default LifestylePage
